import { render, staticRenderFns } from "./VtkTwoView.vue?vue&type=template&id=150c15f4&scoped=true&"
import script from "./VtkTwoView.vue?vue&type=script&lang=ts&"
export * from "./VtkTwoView.vue?vue&type=script&lang=ts&"
import style0 from "./VtkTwoView.vue?vue&type=style&index=0&lang=css&"
import style1 from "@/src/components/styles/vtk-view.css?vue&type=style&index=1&id=150c15f4&scoped=true&lang=css&"
import style2 from "@/src/components/styles/utils.css?vue&type=style&index=2&id=150c15f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150c15f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemTitle,VMenu,VProgressCircular,VTextField})
