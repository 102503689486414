<template>
<div>
  <div v-if="!$auth.isAuthenticated">
    <v-overlay>
      <span>{{ $i18n.t('app.loggingIn') }}</span>
      <v-progress-circular indeterminate :size="50" />
    </v-overlay>
  </div>

  <div v-else-if="showApp">
    <App />
  </div>

  <div v-else-if="!demoDataLoaded">
    <v-overlay>
      <span>{{ $i18n.t('app.loadingDemoData') }}...</span>
      <v-progress-circular indeterminate :size="50" />
    </v-overlay>
  </div>
</div>
</template>

<script>
import App from '../components/App.vue';
import { openDicomFiles } from '../io/upload';
import { isDemo, getDemoFiles } from './demo';

const onUnloadingPage = (event) => {
  event.preventDefault();
};

export default {
  components: { App },
  data() {
    return {
      demoDataLoaded: false
    }
  },
  mounted() {
    window.addEventListener('beforeunload', onUnloadingPage);

    if (!this.$auth.loading) {
      if (!this.$auth.isAuthenticated) {
        this.$auth.loginWithRedirect();
      }
    } else {
      const unwatch = this.$watch(() => this.$auth.loading, (loading) => {
        // If still loading, do nothing
        if (loading) {
          return;
        }

        // If it turns out user is already authenticated, abort
        if (this.$auth.isAuthenticated) {
          if (isDemo()) {
            getDemoFiles().then(openDicomFiles).then(() => {
              this.demoDataLoaded = true
            });
          }
          unwatch();
          return;
        }

        // If loaded and user is not authenticated, then start the login process
        this.$auth.loginWithRedirect();
        unwatch();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', onUnloadingPage);
  },
  computed: {
    /**
     * Show app only if the user is authenticated and we have the user data.
     * If the user is a "demo only" user, wait for the demo dataset to be loaded to show the application.
     */
    showApp() {
      return this.$auth.isAuthenticated && this.$auth.user && (!this.$auth.user.demo || (this.$auth.user.demo && this.demoDataLoaded));
    }
  }
}
</script>
